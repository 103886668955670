export const publicEmailDomains = [
  "gmail.com",
  "outlook.com",
  "hotmail.com",
  "yahoo.com",
  "yandex.com",
  "googlemail.com",
  "mail.ru",
  "yandex.ru",
  "protonmail.com",
  "protonmail.me",
  "icloud.com",
  "hey.com",
];
